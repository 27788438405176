<!--
 * @Author: diaowangtao
 * @Date: 2020-08-21 16:27:42
 * @Description: 
-->
<template>
  <div class="job">
    <div class="job_top_out">
      <div class="job_top">
        <div class="job_title">{{info.title|sLang}}</div>
        <div class="job_time">{{info.city|sLang}} {{info.time|sLang}}</div>
        <div class="job_message">{{info.type|sLang}}</div>
      </div>
    </div>
    <div class="content" v-html="$options.filters.sLang(info.content)"></div>
  </div>
</template>

<script>
import { getJob } from '@/api/joinUs'
export default {
  name: 'job',
  data() {
    return {
      info: {
        title: '',
        type: '',
        city: '',
        time: '',
        content: '',
      },
    }
  },
  created() {
    getJob({ id: this.$route.query.id }).then((res) => {
      this.info = {
        title: res.article.title,
        type: res.article.pageTitle,
        city: res.article.author,
        time: this.$dayjs(res.publishTime).format('YYYY-MM-DD'),
        content: res.article.content,
      }
    })
  },
  mounted() {},
  methods: {},
}
</script>

<style lang='scss'>
.job {
  .job_top_out {
    background: #faf4eb;
    width: 100%;
    .job_top {
      width: 815px;
      height: 147px;
      margin: 0 auto;
      overflow: hidden;
      .job_title {
        font-size: 24px;
        color: #232d44;
        margin: 30px 0 10px 0;
        height: 36px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .job_time {
        font-size: 13px;
        color: #8d909b;
      }
      .job_message {
        display: inline-block;
        padding: 4px 8px;
        font-size: 12px;
        color: #575c6d;
        background: #fff;
        margin-top: 10px;
      }
    }
  }
  .content {
    width: 815px;
    margin: 0 auto;
    padding: 40px 0 100px 0;
    font-size: 14px;
    color: #575c6d;
    line-height: 20px;
  }
}
</style>