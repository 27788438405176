import request from '@/utils/request'

export function joinUsDetail(data = {}) {
  return request({
    url: '/joinUs/',
    method: 'post',
    data
  })
}

export function getIntroduction(data = {}) {
  return request({
    url: '/joinUs/introduction',
    method: 'post',
    data
  })
}

export function getJobList(data = {}) {
  return request({
    url: '/joinUs/jobList',
    method: 'post',
    data
  })
}

export function getStatement(data = {}) {
  return request({
    url: '/joinUs/statement',
    method: 'post',
    data
  })
}

export function getJob(data) {
  return request({
    url: '/joinUs/job',
    method: 'post',
    data
  })
}
